import React, { useContext, useState } from "react"
import Wrapper from "../Elements/Wrapper"
import Headline from "../Elements/Headline"
import Form from "../Elements/Form"
import Block from "../Elements/Block"
import { OptionsContext } from "../../templates/Page"
import "./ApplicationForm.scss"
import axios from "axios"

export default ({ id, data = false }) => {
  try {
    let { application: { form, target, headline, success = '/' }, jobs } = useContext(OptionsContext)
    let position = data ? data.job : ""

    let [progress, setProgress] = useState(() => false)

    let submittableFields = form.reduce((fields, field) => {
      if (field.type === "jobs" && !fields.position) {
        fields.position = field.data.name
      }
      if (field.type === "input" && field.data.type === "email" && !fields.email) {
        fields.email = field.data.name
      }
      if (field.type === "upload" && !fields.files) {
        fields.files = field.data.name
      }
      return fields
    }, { email: "", position: "", files: "" })

    let handleSubmit = (values) => {

      let $form = document.getElementById(`${id}-form`)

      let data = new FormData()
      data.append("email", values[submittableFields.email])
      data.append("position", document.querySelector(`button[value="${values[submittableFields.position]}"]`).innerText)
      for (let file of values[submittableFields.files]) {
        data.append("files", file)
      }

      $form.classList.add("form--loading")
      axios.post(
        target,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            setProgress(Math.round(event.loaded * 100 / event.total))
          },
        },
      ).then(data => {
        if (data.status !== 200) {
          throw Error("API Error")
        }
        window.location = success;
        $form.classList.remove("form--loading")
        setProgress(false)
      }).catch(e => {
        console.error(e)
        alert(e);
        $form.classList.remove("form--loading")
        setProgress(false)
      })
    }
    if (jobs.length === 0) {
      return null
    }
    return (
      <Block id={id} block="application-form">
        <Wrapper>
          <Headline modifier="headline">{headline}</Headline>
          <Form id={`${id}-form`} action={target} modifier="application" fields={form} submitCallback={handleSubmit}
            initValues={{ position }} progress={progress} />
        </Wrapper>
      </Block>
    )
  } catch (e) {
    console.log("ApplicationForm failed to render")
    return null
  }
}