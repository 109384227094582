import ApplicationForm from "./Blocks/ApplicationForm";
import CallToAction from "./Blocks/CallToAction";
import Career from "./Blocks/Career"
import Careers from "./Blocks/Careers"
import Clients from "./Blocks/Clients";
import Content from "./Blocks/Content"
import ContactForm from "./Blocks/ContactForm"
import Features from "./Blocks/Features";
import Hero from "./Blocks/Hero";
import Product from "./Blocks/Product"
import ProductFeatures from "./Blocks/ProductFeatures"
import Skills from "./Blocks/Skills";
import SuccessStory from "./Blocks/SuccessStory"
import TextIcon from "./Blocks/TextIcon"
import TextIcons from "./Blocks/TextIcons"
import TextImage from "./Blocks/TextImage";
import HeroImage from "./Blocks/HeroImage";

export const Blocks = {
  'application-form': ApplicationForm,
  'call-to-action': CallToAction,
  'career': Career,
  'careers': Careers,
  'clients': Clients,
  'contact-form': ContactForm,
  'content': Content,
  'features': Features,
  'hero': Hero,
  'product': Product,
  'product-features': ProductFeatures,
  'skills': Skills,
  'success-story': SuccessStory,
  'text-icon': TextIcon,
  'text-icons': TextIcons,
  'text-image': TextImage,
  'hero-image': HeroImage
};

export const isIeOrEdge = () => {
  try {
    let ua = window.navigator.userAgent, browser;
    browser = ua.indexOf('MSIE');
    if (browser > 0) {
      return 'ie';
    }
    browser = ua.indexOf('Trident/');
    if (browser > 0) {
      return 'ie';
    }
    browser = ua.indexOf('Edge/');
    return browser > 0 ? 'ms-edge' : false;
  } catch (e) {
    return false
  }
};