import React from "react"
import Copy from "./Copy";
import Headline from "./Headline";
import Icon from "./Icon";
import LinkOrA from "./LinkOrA"
import { UnlinkedCta } from "./Cta";

export default ({modifier = 'default', items = []}) => (
  <ul className={`list list--icon list--${modifier}`}>
    {items.map( (item, key) => {
      let {img = false, svg = false, link = {url: '#undefined', title: 'undefined'}, headline, copy} = item;
      return (
        <li key={key} className={`list__item`}>
          <LinkOrA to={link.url} className="list__link">
            <Icon img={img} svg={svg}/>
            <Headline tag="h3" modifier="subtitle">{headline}</Headline>
            <Copy modifier={modifier} copy={copy} />
            <UnlinkedCta link={link} modifier={modifier} />
          </LinkOrA>
        </li>
      )
    })}
  </ul>
)