import React from "react"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Headline from "../Elements/Headline"
import Cta from "../Elements/Cta"
import Image from "../Elements/Image"
import './HeroImage.scss'

export default ({id, data: {modifier = 'light', headline, contentModifier = 'right', cta, img, headlineColor}}) => (
    <Block id={id} block="hero-image" modifier={modifier}>
        <Wrapper>
            <Wrapper block="hero-image" modifier={contentModifier}>
                <Headline modifier="headline"><span>{headlineColor}</span> {headline}</Headline>
                <Cta link={cta} />
            </Wrapper>
        </Wrapper>
        <Image img={img} modifier='fullscreen' />
    </Block>
)