import React, { useState } from "react"
import Block from "../Elements/Block"
import Copy from "../Elements/Copy"
import Form from "../Elements/Form"
import Headline from "../Elements/Headline"
import IconTeaser from "../Elements/IconTeaser"
import Wrapper from "../Elements/Wrapper"
import './ContactForm.scss'
import axios from "axios"

export default ({id, data: {headline, copy, cta = false, target, error='Error', success = '/', form = [], teaser = false}}) => {

  let [progress, setProgress] = useState(() => false);

  let handleSubmit = (values) => {
    let $form = document.getElementById(`${id}-form`)
    let submittableFields = form.reduce((fields, field) => {
      if (field.type === 'submit') {
        return fields
      }
      if (fields.hasOwnProperty(field.data.name)) {
        fields[field.data.name] = true;
      } else {
        console.warn(`${field.data.name} is not mapped`)
      }
      return fields
    }, {
      name: false,
      email: false,
      company: false,
      phone: false,
      country: false,
      position: false,
      industry: false,
      volume: false,
      message: false,
    })

    let data = new FormData()
    Object.keys(submittableFields).map(name => {
      if (!submittableFields[name]) {
        console.warn(`${name} is not available`)
      }
      return data.append(name, values[name])
    });

    $form.classList.add("form--loading")
    axios.post(
      target,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (event) => {
          setProgress(Math.round(event.loaded * 100 / event.total ));
        }
      }
    ).then(data => {
      if (data.status !== 200) {
        throw Error("API Error")
      }
      window.location = success;
      $form.classList.remove("form--loading")
      setProgress(false)
    }).catch(e => {
      console.error(e)
      alert(e);
      $form.classList.remove("form--loading")
      setProgress(false)
    })
  }
  return (
    <Block id={id} block="contact-form">
      <Wrapper>
        <Headline tag="h1" modifier="headline">{headline}</Headline>
        <Copy modifier="centered" copy={copy} />
        <Form id={`${id}-form`} action={target} modifier="contact" fields={form} submitCallback={handleSubmit} progress={progress} />
        <IconTeaser {...teaser}/>
      </Wrapper>
    </Block>
  )
}