import React from "react"
import LinkOrA from "./LinkOrA"
import { InlineSvg } from "./Svg"
import Image from "./Image"
import "./Logo.scss"

export default ({ modifier, link = false, svg = false, img = false }) => {
  let logo = 'LOGO';
  if (svg) {
    logo = (<InlineSvg viewBox={svg.viewBox} markup={svg.markup} style={{fill: "currentColor"}}/>);
  } else if (img) {
    logo = (<Image modifier="logo" img={img} />);
  }

  return link ? (
    <LinkOrA className={`logo logo--${modifier}`} to={link.url}>{logo}</LinkOrA>
  ) : (
      <span className={`logo logo--${modifier}`}>{logo}</span>
    )
}