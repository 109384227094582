export default class FormValidator {
  constructor(settings = {}) {
    this.settings = Object.assign({
      $form: false
    }, settings);

    if (!this.settings.$form) {
      console.warn('FormValidator needs proper configurations');
      return;
    }
    this.validators = {
      email: value => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
      },
      url: value => {
        return /^(http|https):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?$/.test(value)
      },
      number: value => {
        return !isNaN(parseFloat(value)) && isFinite(value);
      },
      integer: value => {
        return !isNaN(parseInt(value, 10)) && (parseFloat(value) === parseInt(value, 10));
      },
      hasMinAge: (value, minAge) => {
        let date = new Date(value),
          age = 0;
        if (!isNaN(date.getTime())) {
          let now = new Date(), month;
          age = now.getFullYear() - date.getFullYear();
          month = now.getMonth() - date.getMonth();
          if (month < 0 || (month === 0 && now.getDate() < date.getDate())) {
            --age;
          }
          return age >= minAge;
        }
      },
      fileOfType: (files, type) => {
        if (typeof files === 'undefined') {
          return false;
        }
        if (!Array.isArray(files)) {
          files = Array.from(files);
        }
        let isFileOfType = !!files.length;
        files.forEach(file => {
          if (!file.type.match(new RegExp(`${type}`))){
            isFileOfType = false;
          }
        });
        return isFileOfType;
      },
      fileOfSize: (files, size) => {
        if (typeof files === 'undefined') {
          return false;
        }
        let isFileOfSize = true;
        if (!Array.isArray(files)) {
          files = Array.from(files);
        }
        files.forEach(file => {
          if (!file.size > size) {
            isFileOfSize = false;
          }
        });
        return isFileOfSize;
      }
    };
  }

  setValidator(key, callback) {
    this.validators[key] = callback
  }

  unsetValidator(key) {
    this.validators[key] = null;
  }

  validate() {
    let $$invalidFields = [];
    for (let $field of this.settings.$form.querySelectorAll('input:not(:disabled), select:not(:disabled), textarea:not(:disabled)')) {
      if (!$field) {
        return;
      }
      if (this.__validateField($field) === false) {
        $$invalidFields.push($field)
      }
    }
    return $$invalidFields;
  }

  __validateField($field) {
    let isValid = true;
    let type = $field.dataset && $field.dataset.inputType ? $field.dataset.inputType : $field.type;
    let value = $field.value || '';
    value = value.trim();
    if (value !== '' ) {
      if (typeof this.validators[type] === 'function' && !this.validators[type](value)) {
        isValid = false;
      }
      if (type === 'min-age') {
        isValid = this.validators.hasMinAge(value, parseInt($field.dataset.minAge, 10));
      }
      if ($field.hasAttribute('min') && parseFloat(value) < $field.getAttribute('min')) {
        isValid = false;
      }
      if ($field.hasAttribute('max') && parseFloat(value) > $field.getAttribute('max')) {
        isValid = false;
      }
    }
    if (type === 'file') {
        let isValidFileType = false;
      let fileTypes =  $field.dataset.type || '/';
      fileTypes.split(',').map(fileType => isValidFileType = isValidFileType || this.validators.fileOfType($field.files, fileType));
      let isValidFileSize = true;
      let fileSize = $field.dataset.size || 0;
      if (fileSize > 0) {
        isValidFileSize = false;
        isValidFileSize = this.validators.fileOfSize($field.files, )
      }
      isValid = isValidFileType && isValidFileSize;
      if (isValid) {
        value = $field.files;
      }
    }
    if ($field.required) {
      if (type === 'radio' || type === 'checkbox') {
        isValid = this.settings.$form.querySelectorAll(`[name="${$field.name}"]:checked`).length > 0;
      } else if (value === '') {
        isValid = false;
      }
    }
    return isValid;
  }
}