import React from "react"
import Block from "../Elements/Block";
import Wrapper from "../Elements/Wrapper";
import Headline from "../Elements/Headline";
import Copy from "../Elements/Copy";
import LinkedIconList from "../Elements/LinkedIconList";
import "./Features.scss"

export default ({id, data: {headline, copy, icons, img}}) => (
  <Block id={id} block="features">
    <Wrapper>
      <Wrapper block="features">
        <Headline tag="h1" modifier="headline">{headline}</Headline>
        <Copy copy={copy} />
        <LinkedIconList items={icons} modifier="feature" />

{/*
        <Image img={img} modifier="feature" />
*/}

      </Wrapper>
    </Wrapper>
  </Block>
)