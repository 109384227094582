import React from "react"
import LinkOrA from "./LinkOrA"
import "./Button.scss"

export default ({ modifier = "primary", type = "button", handleClick = () => console.warn("handleClick on <Button /> is missing"), children }) => (
  <button className={`button button--${modifier}`} type={type} onClick={handleClick}>{children}</button>
)

export const LinkButton = ({ modifier, link }) => (
  <LinkOrA to={link.url} className={`button button--${modifier}`}>{link.title}</LinkOrA>
)