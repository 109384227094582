export const scrollTop = () => {
  if (typeof window.pageYOffset !== 'undefined') {
    return window.pageYOffset;
  } else {
    let d = document,
      r = d.documentElement,
      b = d.body;
    return r.scrollTop || b.scrollTop || 0;
  }
};

export const Ease = {
  inOutQuart: (t, b, c, d) => {
    if ((t /= d / 2 ) < 1) return c / 2 * t * t + b;
    return -c / 2 * ((--t) * (t - 2) - 1) + b;
  }
};

export default class {
  constructor(settings) {
    this.settings =  {
      start: scrollTop(),
      distance: 0,
      duration: 250,
      time: 0,
      increment: 20,
      scrollOnInit: true,
    };
    this.settings = Object.assign({}, this.settings, settings);
    this.onInit = this.onInit.bind(this);
    if (this.settings.scrollOnInit) {
      this.onInit();
    }
  }

  onInit() {
    this.settings.time += this.settings.increment;
    let distance = Ease.inOutQuart(this.settings.time, this.settings.start, this.settings.distance, 250);
    if (this.settings.time < this.settings.duration) {
      window.scrollTo(0, distance);
      requestAnimationFrame(this.onInit);
    } else {
      window.scrollTo(0, this.settings.start + this.settings.distance);
    }
  }
}