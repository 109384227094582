import React from "react"
import MenuItem from "./MenuItem"
import "./MenuList.scss"

function getItemModifier(modifier) {
  switch (modifier) {
    case "root": return 0;
    case "lang": return "lang";
    case "social": return 0;
    default: return modifier + 1;
  }
}

export default ({ modifier, items = []}) => (
  <ul className={`menu__list menu__list--${modifier}`}>
    {items.map((item, i) =>
      (<MenuItem key={i} modifier={getItemModifier(modifier)} item={item}/>),
    )}
  </ul>
);