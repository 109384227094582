import React, { useState } from "react"
import Checkbox from "./FormFields/Checkbox"
import Countries from "./FormFields/Countries"
import DropZone from "./FormFields/DropZone"
import Input from "./FormFields/Input"
import Jobs from "./FormFields/Jobs"
import Phone from "./FormFields/Phone"
import Select from "./FormFields/Select"
import Submit from "./FormFields/Submit"
import Textarea from "./FormFields/Textarea"
import "./Form.scss"
import Vcard from "./FormFields/Vcard"
import FormValidator from "../../FormValidator"
import ScrollTo from "../../ScrollTo"

export const FormFields = {
  "checkbox": Checkbox,
  "upload": DropZone,
  "text": Input,
  "email": Input,
  "phone": Phone,
  "select": Select,
  "jobs": Jobs,
  "countries": Countries,
  "submit": Submit,
  "textarea": Textarea,
  "vcard": Vcard,
}

export const getFrom = (field, obj) => {
  return obj.hasOwnProperty(field) ? obj[field] : null
}

export default ({ id, action = '/', modifier = "default", fields = [], children, submitCallback = e => {e.preventDefault(); console.warn("To be implemented")}, initValues = {}, progress = false }) => {
  let defaultState = {};
  fields.map((item, index) => {
    if (item.type === 'vcard' || !item.data.hasOwnProperty('name')) {
      return '';
    }
    return defaultState[item.data.name] = "";
  })

  let [values, setValues] = useState(() => {
    return Object.assign({}, defaultState, initValues)
  })
  let [errors, setErrors] = useState(() => defaultState)

  let handleChange = ({ target: { name, value } }) => {
    let newValues = Object.assign({}, values);
    let newErrors = Object.assign({}, errors);
    newValues[name] = value
    newErrors[name] = false
    setValues(newValues)
    setErrors(newErrors)
  }
  let handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {},
      Validator = new FormValidator({
        $form: document.getElementById(id)
      });
    let $$invalidFields = Validator.validate();
    if ($$invalidFields.length > 0) {
      $$invalidFields.forEach($field => newErrors[$field.name] = true);
      new ScrollTo({
        distance: $$invalidFields[0].getBoundingClientRect().top,
      });
      setErrors(newErrors);
      return false;
    }
    submitCallback(values);
    return false;
  }
  return (
    <form onSubmit={handleSubmit} action={action} encType="multiform/form-data" method="post"
          className={`form form--${modifier}`}
          noValidate={true} id={id}>
      <>
        {progress !== false && (<progress className={`progress progress--${modifier}`} value={progress} max="100" />)}
        {fields.map((item, key) => {
          let type = item.data.type || item.type;
          if (!FormFields.hasOwnProperty(type)) {
            return null;
          }
          let {name} = item.data;
          let FormField = FormFields[type];
          let props = {
            key,
            id: `${id}-${name}`,
            value: getFrom(name, values),
            isError: getFrom(name, errors),
            handleChange
          }
          return (
            <FormField {...item.data} {...props} />
          )
        }) }
      </>
    </form>
  )
}