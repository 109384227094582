import React from "react"
import Field, { FieldWrapper } from "./Field"
import "./Checkbox.scss"
import Svg from "../Svg"

export default ({ label, type, id, name, value, options, handleChange, error, isError = false, required }) => (
  <FieldWrapper modifier="has-checkbox" error={error} isError={isError}>
    <label className="visually-hidden" dangerouslySetInnerHTML={{__html: label}} />
    {Object.keys(options).map((option, key) => (
      <Field type={type} label={options[option]} key={key} id={`${id}-${option}`} error={error} isError={isError}>
        <input className={`form__input form__input--${type}`} id={`${id}-${option}`} name={name} value={option}
               checked={option === value} onChange={(e) => {
          let event = { target: { name, value: option === value ? "" : option } }
          handleChange(event)
        }} type={type} required={required}/>
        <span className="form__checkbox">
          <Svg id="check" />
        </span>
      </Field>
    ))}
  </FieldWrapper>
)