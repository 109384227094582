import React from "react"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Headline from "../Elements/Headline"
import Copy from "../Elements/Copy"
import Cta from "../Elements/Cta"
import Image from "../Elements/Image"
import "./TextImage.scss"

export default ({id, data: {
  modifier = 'left',
  contentModifier = 'half',
  headline,
  copy,
  cta = false,
  img,
  imgModifier = {
    size:'cover',
    x: 'center',
    y: 'center',
    hideOnMobile: false
  }
}}) => {
  let imageModifier = `${imgModifier.size}-${imgModifier.x}-${imgModifier.y}`;
  if (imgModifier.hideOnMobile) {
    modifier += ' text-image--hide-on-mobile';
  }
  return (
    <Block id={id} block="text-image" modifier={modifier}>
      <Wrapper>
        <Wrapper block="text-image" modifier={contentModifier}>
          <Headline modifier="headline">{headline}</Headline>
          <Copy copy={copy} />
          {cta && (<Cta link={cta} />)}
        </Wrapper>
        <Image img={img} modifier={imageModifier} />
      </Wrapper>
    </Block>
  )
}