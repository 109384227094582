import React from "react"
import { Link } from "gatsby"

export default ({ to, className, children }) => {
  if (!to)
    return null

  return (to[0] === '/') ? (
    <Link to={to} className={className}>{children}</Link>
  ) : (
      <a href={to} target={to.indexOf('http') === 0 ? '_blank' : undefined} className={className}>{children}</a>
    )
}