import React from "react"
import Block from "../Elements/Block"
import IconTeaser from "../Elements/IconTeaser"
import Wrapper from "../Elements/Wrapper"
import "./TextIcon.scss"

export default ({id, data: {
  headline,
  copy,
  cta = false,
  img = false,
  svg = false
}}) => {
  return (
    <Block id={id} block="text-icon">
      <Wrapper>
        <IconTeaser modifier="text-icon" headline={headline} headlineProps={{tag: 'h1', modifier: 'headline'}} copy={copy} cta={cta} svg={svg} img={img}/>
      </Wrapper>
    </Block>
  )
}