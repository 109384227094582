import React from "react"
import Wrapper from "../Elements/Wrapper"
import Block from "../Elements/Block"
import Headline from "../Elements/Headline"
import Copy from "../Elements/Copy"
import Image from "../Elements/Image"
import Logo from "../Elements/Logo"
import Svg from "../Elements/Svg"
import "./SuccessStory.scss"

export default ({id, data: {modifier = 'left', img, logo, headline, copy}}) => (
  <Block id={id} block="success-story" modifier={modifier}>
    <Wrapper>
      <Wrapper block="success-story">
        <Wrapper block="success-story" modifier="media">
          <Image img={img} />
          <Logo modifier="success-story" {... logo} />
        </Wrapper>
        <Wrapper block="success-story" modifier="quote">
          <Svg id="quote" />
          <Headline modifier="headline">{headline}</Headline>
        </Wrapper>
      </Wrapper>
      <Copy copy={copy} modifier="success-story"/>
    </Wrapper>
  </Block>
)