import React from "react"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Headline from "../Elements/Headline"
import Copy from "../Elements/Copy"
import TickList, {showTooltip} from "../Elements/TickList"
import './ProductFeatures.scss'

export default ({id, data: {headline, copy, features = []}}) => (
  <Block id={id} block="product-features">
    <Wrapper>
      <Headline modifier="headline">{headline}</Headline>
      <Copy copy={copy} />
      <Wrapper block="product-features">
        {features.map((group, key) => {
          let {headline, items = []} = group;
          return (
            <Wrapper key={key} block="product-features" modifier="group">
              <Headline modifier="group">{headline}</Headline>
              <TickList items={items} modifier="interactive" handleClick={showTooltip} />
            </Wrapper>
          )
        })}
      </Wrapper>
    </Wrapper>
  </Block>
)