import React, { useContext } from "react"
import Logo from "./Elements/Logo"
import Toggle from "./Elements/Toggle"
import Svg from "./Elements/Svg"
import MenuList from "./Elements/MenuList"
import Wrapper from "./Elements/Wrapper"
import './Header.scss';
import { LanguageContext, OptionsContext } from "../templates/Page"

function getLanguageLabel(lang, currentLang) {
  for (let language of lang) {
    if (language.locale === currentLang) {
      return language.label;
    }
  }
  return 'undefined'
}

function getCurrentLangBaseUrl(currentLang) {
  if (currentLang === 'de-DE') {
    return '/';
  }
  return '/' + currentLang.substr(0, 2) + '/';
}

function closeLanguageList(e) {
  e.stopPropagation();
  let $toggle = e.currentTarget.querySelector('.menu--toggled');
  if ($toggle) {
    $toggle.classList.remove('menu--toggled');
  }
}

export default ({ menu }) => {
  let menuRef = React.createRef(),
    langRef = React.createRef();
  let { lang, currentLang, setLang } = useContext(LanguageContext);
  let { logo } = useContext(OptionsContext);
  return (
    <header className="page__header header">
      <Wrapper>
        <Logo modifier="header" svg={logo} link={{ url: getCurrentLangBaseUrl(currentLang) }} />
        <nav ref={menuRef} className="page__menu menu menu--main" onClick={closeLanguageList}>
          <div ref={langRef} className="menu menu--languages" onClick={e => {
            setLang(e.target.textContent);
            e.stopPropagation();
          }}>
            <MenuList modifier="lang" items={lang} />
          </div>
          <Toggle modifier="lang" target={langRef}>
            {getLanguageLabel(lang, currentLang)}
            <Svg id="caret" />
          </Toggle>
          <MenuList modifier="root" items={menu} />
        </nav>
        <Toggle target={menuRef}>
          <span className="menu__bar" />
        </Toggle>
      </Wrapper>
    </header>
  );
}