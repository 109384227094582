import React from "react"
import Field from "./Field"
import "./Phone.scss"
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const FORMAT_MARGIN = 4;

export default ({value, label, type, id, name, placeholder, handleChange, error, isError, required = false}) => {
  let currentNumber = value.length > FORMAT_MARGIN ? parsePhoneNumberFromString(value, 'AT') : false;
  return (
    <Field label={label} type={type} id={id} error={error} isError={isError}>
      <div className="phone">
        {currentNumber && <span className="phone__country-code">+{currentNumber.countryCallingCode}</span>}
        <input className={`form__input form__input--${type}`}
               id={id}
               name={name}
               value={value}
               onChange={({target: {name, value: changedValue}}) => {
                 if (value.length > FORMAT_MARGIN) {
                   let phoneNumber = parsePhoneNumberFromString(changedValue, 'AT');
                   if (phoneNumber) {
                     changedValue = phoneNumber.format('INTERNATIONAL');
                   }
                 }
                 handleChange({target: {
                     name,
                     value: changedValue
                   }});
               }}
               placeholder={placeholder}
               type={type}
               required={required} />
      </div>
    </Field>
  )
}