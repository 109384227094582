import React, { useState } from "react"
import Field from "./Field"
import "./Textarea.scss"

const DEFAULT_ROWS = 3;
const PADDING_Y = 16;

export default ({label, type, id, name, value, handleChange, placeholder, error, isError, required = false}) => {
  let [rows, setRows] = useState(() => DEFAULT_ROWS)
  return (
    <Field label={label} type="textarea" id={id} error={error} isError={isError}>
      <textarea className='form__input form__input--textarea'
                id={id}
                name={name}
                value={value}
                onChange={e => {
                  e.target.rows = 1;
                  const currentRows = ~~(e.target.scrollHeight / (e.target.offsetHeight - PADDING_Y));
                  e.target.rows = currentRows > DEFAULT_ROWS ? currentRows : DEFAULT_ROWS;
                  setRows(e.target.rows);
                  handleChange(e);
                }}
                placeholder={placeholder}
                rows={rows}
                required={required}
      />
    </Field>
  )
}