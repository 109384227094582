import React from "react"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Headline from "../Elements/Headline"
import Copy from "../Elements/Copy"
import Cta from "../Elements/Cta"
import './Content.scss'

export default ({id, data: { modifier, headline, headlineTag, headlineModifier, copy, cta = false}}) => (
  <Block id={id} block="content" modifier={modifier}>
    <Wrapper modifier="content">
        <Headline tag={headlineTag} modifier={headlineModifier}>{headline}</Headline>
        <Copy copy={copy} />
        {cta && (<Cta link={cta} />)}
    </Wrapper>
  </Block>
)