import React from "react"
import './Headline.scss';

export default ({ tag: Tag = 'h2', modifier = 'title', children }) => {
  if (typeof children === 'string') {
    if (children.match(/\|/g)) {
      const replaced = children.replace(/\|/g, "<br/>");
      return (<Tag dangerouslySetInnerHTML={{__html: replaced}} className={`page__title page__title--${modifier}`}></Tag>)
    }
    return (<Tag className={`page__title page__title--${modifier}`}>{children}</Tag>)
  } else {
    return (<Tag className={`page__title page__title--${modifier}`}>{children}</Tag>)
  }
}
