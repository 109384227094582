import React, {useContext} from "react"
import Select from './Select';
import {OptionsContext} from "../../../templates/Page"

export default (props) => {
  let {countries} = useContext(OptionsContext)

  return (
    <Select {...props} type="select" search={true} options={countries}/>
  )
}