import React from "react"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Headline from "../Elements/Headline"
import Copy from "../Elements/Copy"
import IconList from "../Elements/IconList"
import "./Skills.scss"

export default ({id, data: {modifier = '', headline, copy, icons}}) => (
  <Block id={id} block="skills" modifier={modifier}>
    <Wrapper>
      <Wrapper block="skills">
        <Headline modifier="headline">{headline}</Headline>
        <Copy copy={copy} />
      </Wrapper>
      <IconList items={icons} modifier="skill" />
    </Wrapper>
  </Block>
)