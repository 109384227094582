import React from "react"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Headline from "../Elements/Headline"
import Copy from "../Elements/Copy"
import Cta from "../Elements/Cta"
import Image from "../Elements/Image"
import './Hero.scss'

export default ({id, data: {modifier = 'light', headline, contentModifier = 'right', copy, cta, img}}) => (
  <Block id={id} block="hero" modifier={modifier}>
    <Wrapper>
      <Wrapper block="hero" modifier={contentModifier}>
        <Headline modifier="headline">{headline}</Headline>
        <Copy copy={copy} />
        <Cta link={cta} />
      </Wrapper>
    </Wrapper>
    <Image img={img} modifier='fullscreen' />
  </Block>
)