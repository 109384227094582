import React from "react"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Headline from "../Elements/Headline"
import TickList from "../Elements/TickList"
import "./Benefits.scss"

export default ({ id, data: { headline, benefits = [] } }) => (
  <Block id={id} block="benefits">
    <Wrapper block="benefits">
      <Headline>{headline}</Headline>
    </Wrapper>
    <Wrapper modifier="grey">
      <Wrapper block="benefits">
      <TickList items={benefits} modifier="column"/>
      </Wrapper>
    </Wrapper>
  </Block>
)