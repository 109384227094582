import React, { useContext } from "react"
import { InlineSvg } from "./Svg"
import { OptionsContext } from "../../templates/Page"
import "./GdprBadge.scss"

export default () => {
  let {gdpr} = useContext(OptionsContext);
  return (
    <div className="gdpr-badge">
      <InlineSvg markup={gdpr.markup} viewBox={gdpr.viewBox}/>
    </div>
  )
}