import React from "react"
import Svg from "./Svg"
import LinkOrA from "./LinkOrA"
import "./Cta.scss"

const defaultLink = {
  url: "#",
  title: "Read more",
}

export default ({ modifier = "default", link }) => (
  (link) ?
    (<LinkOrA to={link.url} className={`cta cta--${modifier}`}>
      {link.title}
      <span className="cta__icon">
        <Svg id="arrow" />
      </span>
    </LinkOrA>)
    : (null)
);

export const UnlinkedCta = ({ modifier = "default", link = defaultLink }) => (
  <span className={`cta cta--${modifier}`}>
    {link.title}
    <span className="cta__icon">
      <Svg id="arrow" />
    </span>
  </span>
)