import React, { useContext} from "react"
import Select from './Select';
import { OptionsContext } from "../../../templates/Page"

export default (props) => {
  let {jobs, jobChoose = 'Choose an open position'} = useContext(OptionsContext)
  let options = jobs.reduce((accumulated, job) => {
    accumulated[job.path] = job.title;
    return accumulated;
  }, {'': jobChoose});
  return (
    <Select {...props} type="select" search={true} options={options} />
  )
}