import React, { useState } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Blocks, isIeOrEdge } from "../components/Utils"
import SEO from "../components/Blocks/Seo"
import { SvgSprite } from "../components/Elements/Svg"
import { hideAllTooltips } from "../components/Elements/TickList"
import { untoggle } from "../components/Elements/Toggle"
import "./Page.scss"
import Image from "../components/Elements/Image"
import Cookiebar from "../components/Elements/Cookiebar"

export const OptionsContext = React.createContext()
export const LanguageContext = React.createContext()
export const SelectContext = React.createContext()

const Page = ({ pageContext: { modifier, menus, lang, data, option, meta, currentLang } }) => {
  let { sprite = false, cookiebar = false, selectSearchPlaceholder: selectSearch = 'Search…' } = option
  let { thumbnail = false } = meta
  let [select, setSelect] = useState(() => false);

  let closeAll = () => {
    hideAllTooltips()
    untoggle()
    setSelect(false);
  }

  let blockIndex = {}
  let getBlockIndex = (type) => {
    if (!blockIndex.hasOwnProperty(type)) {
      blockIndex[type] = 0
    }
    return ++blockIndex[type]
  }

  function setLang(lang) {
    localStorage.setItem("preferredLanguage", lang);
    currentLang = lang;
  }

  return (
    <LanguageContext.Provider value={{ lang, currentLang, setLang }}>
      <OptionsContext.Provider value={{ ...option }}>
        <SelectContext.Provider value={{ select, setSelect, selectSearch }}>
          <div className={`page page--${modifier}${isIeOrEdge() ? ' page--is-legacy' : ''}`} onClick={closeAll}>
            <SEO meta={meta} />
            {thumbnail && (<Image modifier="header" img={thumbnail} />)}
            <Header menu={menus.header} lang={lang} currentLang={currentLang} />
            <main className="page__main">
              {data.map((block, index) => {
                let Component = Blocks[block.type]
                return (<Component key={index} data={block.data} id={`${block.type}-${getBlockIndex(block.type)}`} />)
              })}
            </main>
            <Footer menu={menus.footer} />
            {cookiebar && (<Cookiebar />)}
            {sprite && (<SvgSprite sprite={sprite} />)}
          </div>
        </SelectContext.Provider>
      </OptionsContext.Provider>
    </LanguageContext.Provider>
  )
}

export default Page