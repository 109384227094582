import React, {useContext} from "react"
import { InlineSvg } from "./Svg"
import { OptionsContext } from "../../templates/Page"
import "./TickList.scss"

export function hideAllTooltips($target) {
  for (let $item of document.querySelectorAll('.list__item--show-tooltip')) {
    if ($target === $item) {
      continue;
    }
    $item.classList.remove('list__item--show-tooltip')
  }
}

export function showTooltip(e) {
  e.stopPropagation();
  let $target = e.target;
  while(true) {
    if ($target.classList.contains('tooltip')) {
      return;
    }
    if ($target.classList.contains('list')) {
      hideAllTooltips();
      return;
    }
    if ($target.classList.contains('list__item--has-tooltip')) {
      break;
    }
    $target = $target.parentNode;
  }
  hideAllTooltips($target);
  $target.classList.toggle('list__item--show-tooltip');
}

export default (
  {
    modifier = 'default',
    items = [],
    handleClick = () => console.warn('handleClick on <TickList /> is missing')
  }) => {
  let {tick} = useContext(OptionsContext);
  return (
    <ul className={`list list--tick list--${modifier}`}>
      {items.map( (item, key) => (
        <li key={key} className={`list__item${item.tooltip ? ' list__item--has-tooltip': ''}`} onClick={handleClick}>
          <InlineSvg viewBox={tick.viewBox} markup={tick.markup} />
          {item.label}
          {item.tooltip && (<span className="tooltip">{item.tooltip}<span className="tooltip__icon"></span></span>)}
        </li>
      ))}
    </ul>
  );
}