import React from "react"
import Wrapper from "./Wrapper"
import "./Toggle.scss"

export function untoggle(toggleClass = 'menu--toggled', $target = false) {
  for (let $item of document.querySelectorAll(`.${toggleClass}`)) {
    if ($target === $item) {
      continue;
    }
    $item.classList.remove(toggleClass)
  }
}

export default ({block = 'page', modifier = 'menu', target, toggleClass = 'menu--toggled', children, handleClick, id = undefined}) => (
  <button id={id} className={`${block}__toggle ${block}__toggle--${modifier} toggle`} onClick={e => {
    e.stopPropagation();
    if (typeof handleClick === 'function') {
      handleClick(e);
    }
    target.current.classList.toggle(`${toggleClass}`);
  }}>
    <Wrapper block='toggle'>
      {children}
    </Wrapper>
  </button>
)