import React from "react"
import Copy from "./Copy"
import Cta from "./Cta"
import Headline from "./Headline"
import Icon from "./Icon"
import Wrapper from "./Wrapper"
import "./IconTeaser.scss"

export default ({
  modifier = false,
  svg = false,
  img = false,
  headline = false,
  headlineProps = {
    tag: 'h3',
    modifier: 'subtitle'
  },
  copy = false,
  cta = false
}) => {
  if (!headline && !copy) {
    return null
  }
  return (
    <div className={`teaser teaser--icon${modifier? ` teaser--${modifier}`: ''}`}>
      <Icon svg={svg} img={img}/>
      <Wrapper block="teaser">
        {headline && (<Headline {...headlineProps}>{headline}</Headline>)}
        {copy && (<Copy modifier={modifier} copy={copy}/>)}
        {cta && (<Cta link={cta} />)}
      </Wrapper>
    </div>
  )
}