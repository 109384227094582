import React from "react"
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import "./Vcard.scss"
import Image from "../Image"

export default ({name, position, phone = false, mail = false, image = false}) => {
  let phoneLink;
  if (phone) {
    let phoneNumber = parsePhoneNumberFromString(phone, 'AT');
    if (phoneNumber) {
      phoneLink = phoneNumber.format('RFC3966')
      phone = phoneNumber.format('INTERNATIONAL')
    } else {
      phoneLink = 'tel:'+phone;
    }
  }
  return (
    <div className="vcard">
      <h4 className="vcard__name">{name}</h4>
      <p className="vcard__position">{position}</p>
      {phone && (<a className="vcard__phone" href={phoneLink}>{phone}</a>)}
      {mail && (<a className="vcard__email" href={`mailto:${mail}`}>{mail}</a>)}
      <Image modifier="vcard" img={image}/>
    </div>
  )
}