import React, { useContext, useState } from "react"
import Cookie from 'js-cookie'
import { OptionsContext } from "../../templates/Page"
import Button from "./Button"
import Copy from "./Copy"
import Wrapper from "./Wrapper"
import "./Cookiebar.scss"

const COOKIE_NAME = '_springtime_cookie-bar';
const COOKIE_SETTINGS = {
  expires: 365,
  path: '/'
};

export default () => {
  let {cookiebar: { copy = "<p>Cookiebar</p>", close = "Close"}} = useContext(OptionsContext);
  let ref = React.createRef();
  const [cookie, setCookie] = useState(() => Cookie.get(COOKIE_NAME) === "1");
  if (cookie) {
    return null;
  }
  let handleClick = () => {
    Cookie.set(COOKIE_NAME, 1, COOKIE_SETTINGS);
    ref.current.classList.add('cookiebar--hidden');
    ref.current.addEventListener('transitionend', () => {
      setCookie(true);
    })
  }
  return (
    <div className="cookiebar" ref={ref}>
      <Wrapper modifier="cookiebar">
        <Copy copy={copy} modifier="cookiebar" />
        <Button modifier="cookiebar" handleClick={handleClick}>{close}</Button>
      </Wrapper>
    </div>
  );
}