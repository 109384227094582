import React, { useContext, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { SelectContext } from "../../../templates/Page"
import Field from "./Field"
import Svg from "../Svg"
import Wrapper from "../Wrapper"
import "./Select.scss"

export default ({ label, type, id, name, value, options, handleChange, error, isError, required = false, search = false }) => {
  let selectRef = React.createRef(),
    toggleClass = "select--toggled"

  let { select, setSelect, selectSearch } = useContext(SelectContext)
  let [searchTerm, setSearchTerm] = useState(() => "")

  let focusedOption = -1
  let handleKeyDown = e => {
    let $target = selectRef.current
    if ($target && (e.key === "ArrowDown" || e.key === "ArrowUp")) {
      e.preventDefault()
      let $$options = $target.querySelectorAll(".option")
      if ($$options.length < 2) {
        return
      }
      focusedOption += e.key === "ArrowDown" ? 1 : -1
      if (focusedOption < 0) {
        focusedOption = $$options.length - 1
      }
      if (focusedOption > $$options.length - 1) {
        focusedOption = 0
      }
      $$options[focusedOption].focus()
    }
  }
  useEffect(() => {
    let $target = ReactDOM.findDOMNode(selectRef.current)
    if ($target) {
      $target.addEventListener("keydown", handleKeyDown)
    }
    return () => {
      if ($target) {
        $target.removeEventListener("keydown", handleKeyDown)
      }
    }
  })

  if (!options.hasOwnProperty(value)) {
    handleChange({
      target: {
        name,
        value: Object.keys(options)[0],
      },
    })
    return null
  }

  let filteredOptions = {}
  if (search) {
    Object.keys(options).map(key => {
      if (key === value || key.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 || options[key].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
        filteredOptions[key] = options[key]
      }
      return false
    })
  } else {
    filteredOptions = options
  }
  return (
    <Field label={label} type={type} id={id} error={error} isError={isError}>
      <div
        className={`form__select select${select === id ? ` ${toggleClass}` : ""}${search ? " select--has-search" : ""}`}
        ref={selectRef}
        aria-live={select === id ? "polite" : "off"}>
        {search && (
          <input className="select__search" placeholder={selectSearch} tabIndex={select === id ? "0" : "-1"}
                 onClick={(e) => {
                   e.preventDefault()
                   e.stopPropagation()
                 }} value={searchTerm} onChange={e => setSearchTerm(e.target.value)}/>
        )}
        <div className="select__wrapper">
          {Object.keys(filteredOptions).map((key, index) => (
            <button className={`select__option option${key === value ? " option--selected" : ""}`}
                    name={name}
                    disabled={key === ""}
                    key={index}
                    value={key}
                    tabIndex={select === id ? "0" : "-1"}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setSearchTerm("")
                      setSelect(false)
                      handleChange(e)
                      selectRef.current.classList.remove(toggleClass)
                    }}>
              <Wrapper block="option">
                <div className="option__icon">
                  <Svg id="check"/>
                </div>
                <div className="option__label">
                  {options[key]}
                </div>
              </Wrapper>
            </button>
          ))}
        </div>
      </div>
      <button id={id} className="form__toggle form__toggle--select toggle" onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        let isToggled = select === id
        if (!isToggled) {
          selectRef.current.querySelector(".option--selected").focus()
        }
        setSelect(isToggled ? "" : id)
      }}>
        <Wrapper block="toggle">
          {options[value]}
          <Svg id="caret"/>
        </Wrapper>
      </button>
      <input type="hidden" name={name} value={value} required={required}/>
    </Field>
  )
}