import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import objectFitImages from 'object-fit-images';
import "./Image.scss"

let defautImg = {
  src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
  alt: false,
  srcset: false
}

let sizes = {
  'default': '100w',
  'feature': '(min-width: 30em) 100w, 300w'
}

export default ({modifier = 'default', img = {}}) => {
  img = Object.assign(defautImg, img);
  img.sizes = sizes.hasOwnProperty(modifier) ? sizes[modifier] : sizes.default;

  let ref = React.createRef();
  useEffect(() => {
    let $target = ReactDOM.findDOMNode(ref.current);
    if ($target) {
      objectFitImages($target)
    }
    return () => {};
  });
  return (
    <figure className={`media media--${modifier}`}>
      <img ref={ref} src={img.src} srcSet={img.srcset ? img.srcset : undefined} alt={img.alt} sizes={img.sizes} className="media__item"/>
    </figure>
  )
}