import React from "react"
import Headline from "./Headline";
import Copy from "./Copy";
import Icon from "./Icon"

export default ({modifier = 'default', items = []}) => (
  <ul className={`list list--icon list--${modifier}`}>
    {items.map( (item, key) => {
      let {img = false, svg = false, headline, copy} = item;
      return (
        <li key={key} className={`list__item`}>
          <Icon svg={svg} img={img} />
          <Headline tag="h3" modifier="subtitle">{headline}</Headline>
          <Copy modifier={modifier} copy={copy} />
        </li>
      )
    })}
  </ul>
)