import React from "react"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Headline from "../Elements/Headline"
import {LinkButton} from "../Elements/Button"
import "./CallToAction.scss"

export default ({id, data: {claim = {question: '', answer: ''}, primary, secondary = false}}) => (
  <Block id={id} block="call-to-action">
    <Wrapper modifier="call-to-action">
      <Wrapper block="call-to-action" modifier="claim">
        <Headline modifier="question">{claim.question}</Headline>
        <Headline modifier="answer">{claim.answer}</Headline>
      </Wrapper>
      <Wrapper block="call-to-action" modifier="buttons">
        <LinkButton modifier="primary" link={primary} />
        {secondary && (<LinkButton modifier="secondary" link={secondary} />)}
      </Wrapper>
    </Wrapper>
  </Block>
)