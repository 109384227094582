import React from "react"
import './Block.scss';

export default ({id, block, modifier = '', children}) => {
  if (modifier) {
    modifier = ` ${block}--${modifier}`
  }
  return (
    <div id={id} className={`page__block page__block--${block} ${block}${modifier}`}>{children}</div>
  )
}