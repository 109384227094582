import React, { useRef, useState } from "react"
import Field  from "./Field"
import "./DropZone.scss"

function getFilenamesFrom(files) {
  let names = Array.from(files).reduce((fileNames, file) => {
    fileNames.push(file.name);
    return fileNames
  }, [])
  return names.join(', ');
}

export default ({ label, id, name, value, handleChange, drag: dragLabel, placeholder, mime = 'application/pdf', size = 0, error, isError = false, required }) => {

  const [drag, setDrag] = useState(false)
  const inputRef = useRef({files: []})

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDrag(false)
    inputRef && (inputRef.current.files = e.dataTransfer.files)
    handleChange({ target: { name, value: e.dataTransfer.files } })
  }

  const handleSelect = (e) => {
    e.target.files && handleChange({ target: { name, value: e.target.files } })
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    setDrag(true)
  }

  const handleDragIn = () => {
    setDrag(true)
  }

  const handleDragOut = () => {
    setDrag(false)
  }
  return (
    <Field type="dropzone" label={label} id={id} error={error} isError={isError}>
      <input className={`form__input form__input--dropzone`} id={id} name={name} onChange={handleSelect}
             placeholder={placeholder} type="file" data-type={mime} data-size={size*1024*1024} ref={inputRef} multiple={true} required={required}/>
      <div className={`form__dropzone${drag ? ' form__dropzone--dragover' : ''}`}
           onClick={() => inputRef && inputRef.current.click()}
           onDrop={handleDrop}
           onDragOver={handleDragOver}
           onDragLeave={handleDragOut}
           onDragEnter={handleDragIn}>
        {isError && error}
        {isError && (<br/>)}
        {drag ? dragLabel : inputRef && inputRef.current.files.length ? getFilenamesFrom(inputRef.current.files) : placeholder}
      </div>
    </Field>
  )
}