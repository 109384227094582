import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import { OptionsContext } from "../../templates/Page"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Select from "../Elements/FormFields/Select"
import Headline from "../Elements/Headline"
import Skills from "./Skills"
import Button from "../Elements/Button"
import Icon from "../Elements/Icon"
import "./Careers.scss"

export default () => {
  try {
    let { careers: { skills, headline, more}, jobs, jobTypes = {}, jobLocations = {} } = useContext(OptionsContext)
    let [type, setType] = useState(() => "all")
    let [location, setLocation] = useState(() => "all")
    let filters = [{
      name: "type",
      value: type,
      handleChange: (e) => setType(e.target.value),
      options: jobTypes,
    }, {
      name: "location",
      value: location,
      handleChange: (e) => setLocation(e.target.value),
      options: jobLocations,
    }];
    let filteredJobs = jobs.filter(job => {
      let addJob = true;
      if (type !== 'all' && job.data.type !== type) {
        addJob = false;
      }
      if (location !== 'all' && job.data.locationBenefit.country !== location) {
        addJob = false;
      }
      return addJob;
    });
    return (
      <>
        <Skills id="skills" data={{ ...skills }}/>
        <Block id="jobs" block="jobs">
          <Wrapper>
            <Headline>{headline}</Headline>
            <div className="form form--filter">
              {filters.map((filter, key) => (
                <Select id={filter.name} key={key} label="" value={filter.value} name={filter.name}
                        handleChange={filter.handleChange} isError={false} options={filter.options} type="filter"/>
              ))}
            </div>
            <Wrapper block="jobs">
              {filteredJobs.map((job, key) => {
                let {
                  path = '',
                  title = '',
                  data: {
                    type,
                    locationBenefit: {
                      svg = false,
                      img = false,
                      locality = '',
                      country = ''
                    }
                  }
                } = job;
                return (
                  <Link className="job job--list" key={key} to={path}>
                    <Headline tag="h3" modifier="job">{title}</Headline>
                    <div className="job__specs">
                      <span className="job__type">{jobTypes[type]}</span>
                    </div>
                    <Wrapper block="job">
                    <span className="location">
                      <Icon svg={svg} img={img} />
                      {locality && (<span className="location__locality">{locality}</span>)}
                      {country && (<span className="location__country">{country.toUpperCase()}</span>)}
                    </span>
                      <Button modifier="job">{more}</Button>
                    </Wrapper>
                  </Link>
                )
              })}
            </Wrapper>
          </Wrapper>
        </Block>
      </>
    )
  } catch (e) {
    console.log("Careers failed to render")
    return null;
  }
}