import React from "react"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Headline from "../Elements/Headline"
import Copy from "../Elements/Copy"
import Cta from "../Elements/Cta"
import IconTeaser from "../Elements/IconTeaser"
import "./TextIcons.scss"

export default ({id, data: {
  modifier = 'left',
  contentModifier = 'half',
  headline,
  copy,
  cta = false,
  icons = [],
}}) => {
  return (
    <Block id={id} block="text-image" modifier={`${modifier} text-image--icons`}>
      <Wrapper>
        <Wrapper block="text-image" modifier={contentModifier}>
          <Headline modifier="headline">{headline}</Headline>
          <Copy copy={copy} />
          {cta && (<Cta link={cta} />)}
        </Wrapper>
        <div className="text-image__icons">
          {icons.map((icon, key) => {
            let {caption = '', svg = false, img = false} = icon;
            return (
              <IconTeaser key={key} modifier="text-image" headline={caption} headlineProps={{tag: 'h3', modifier: 'subtitle'}} svg={svg} img={img}/>
            )
          })}
        </div>
      </Wrapper>
    </Block>
  )
}