import React from "react"

export default ({id}) => (
  <svg className="svg svg--reference"><use xlinkHref={`#svg--${id}`}/></svg>
)

export const InlineSvg = ({viewBox = '0 0 100% 100%', markup}) => (
  <svg className="svg svg--inline" viewBox={viewBox} dangerouslySetInnerHTML={{__html: markup}}/>
)

export const SvgSprite = ({sprite}) => (
  <svg className="svg svg--sprite" dangerouslySetInnerHTML={{__html: sprite}} />
)