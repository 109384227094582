import React from "react"
import "./Field.scss"

export const FieldWrapper = ({ modifier, children, isError = false }) => (
  <div className={`form__field form__field--${modifier}${isError ? " form__field--error" : ""}`}>
    {children}
  </div>
)

export default ({ children, label = "undefined", type, id, error = "", isError = false }) => (
  <FieldWrapper modifier={type} error={error} isError={isError}>
    <label className="form__label" htmlFor={id}>
      <span dangerouslySetInnerHTML={{__html: label}} />
      {isError && (<span className="visually-hidden">{error}</span>)}
    </label>
    {children}
    {isError && (<span className="form__icon form__icon--error">!</span>)}
  </FieldWrapper>
)