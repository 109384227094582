import React from "react"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Countable from "../Elements/Countable"
import Logo from "../Elements/Logo"
import "./Clients.scss"

export default ({id, data: {counters = [], logos = []}}) => (
  <Block id={id} block="clients">
    <Wrapper>
      <ul className="list list--countable">
        {counters.map(({prepend = false, append = false, number = 0, label = '', width = false}, index) => (
          <li className="list__item" key={index}>
            <Countable prepend={prepend} append={append} number={number} label={label} width={width} />
          </li>
        ))}
      </ul>
    </Wrapper>
    <div className="page__section page__section--grey">
      <Wrapper>
        <ul className="list list--logo">
          {logos.map((logo, index) => (
            <li className="list__item" key={index}>
              <Logo modifier="client" {...logo} />
            </li>
          ))}
        </ul>
      </Wrapper>
    </div>
  </Block>
)