import React from "react"
import Image from "./Image"
import LinkOrA from "./LinkOrA"
import MenuList from "./MenuList"
import Svg, { InlineSvg } from "./Svg"
import "./MenuItem.scss"

export default ({ modifier, item }) => {
  return (
    <li
      className={`menu__item menu__item--${modifier}${
        item.active ? " menu__item--active" : ""
      }`}
    
    >
      {item.path ? (
        <LinkOrA to={item.path} className="menu__link">
          {item.svg || item.img || item.icon ? (
            <>
              <span className="menu__icon">
                {item.icon && <Svg id={item.icon} />}
                {item.svg && <InlineSvg {...item.svg} />}
                {item.img && <Image modifier="icon" img={item.img} />}
              </span>
              <span className="menu__label">{item.label}</span>
            </>
          ) : (
            item.label
          )}
        </LinkOrA>
      ) : (
        <span className="menu__link">{item.label}</span>
      )}
      {item.menu && <MenuList items={item.menu} modifier={modifier} />}
    </li>
  )
}
