import React, { useContext } from "react"
import { Link } from "gatsby"
import { LanguageContext, OptionsContext } from "../../templates/Page"
import Benefits from "./Benefits"
import Block from "../Elements/Block"
import Copy from "../Elements/Copy"
import Headline from "../Elements/Headline"
import Svg from "../Elements/Svg"
import Wrapper from "../Elements/Wrapper"
import "./Career.scss"

export default ({ data: { headline, copy, salary, hours, type, locationBenefit: { locality, country, headline: benefitHeadline, benefits } } }) => {
  let { jobBack, jobTypes = {}, jobBackLink } = useContext(OptionsContext)
  let { currentLang } = useContext(LanguageContext)
  return (
    <>
      <Wrapper modifier="job-nav">
        <Link className="back-link" to={`${jobBackLink}#jobs`}>
          <span className="back-link__icon">
            <Svg id="arrow" />
          </span>
          <span className="back-link__label">{jobBack}</span>
        </Link>
        <div className="location-badge">
          <span className="location-badge__locality">{locality}</span>
          <span className="location-badge__country">{country.toUpperCase()}</span>
        </div>
      </Wrapper>
      <Block id="job" block="job" modifier="page">
        <Wrapper modifier="job">
          <Headline tag="h1" modifier="headline">{headline}</Headline>
          <p className="job__specs job__specs--page">
            <span className="job__type">{jobTypes[type]} ({hours.toLocaleString(currentLang)})</span>
            {false && (<span className="job__salary salary">
              <span className="salary__currency">{salary.currency}</span>
              {salary.min && (<span className="salary__min">{salary.min.toLocaleString(currentLang)}</span>)}
              {salary.min && salary.max && "–"}
              {salary.max && (<span className="salary__max">{salary.max.toLocaleString(currentLang)}</span>)}
            </span>)}
          </p>
          <Copy copy={copy}/>
        </Wrapper>
      </Block>
      <Benefits id="benefits" data={{ headline: benefitHeadline, benefits }}/>
    </>
  )
}