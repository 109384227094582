import 'intersection-observer';
import React, { useEffect, useState, useRef } from "react"
import ReactDOM from "react-dom";
import Wrapper from "./Wrapper"
import "./Countable.scss"

function formatNumberByViewportSize(number, size = 480) {
  if (window.innerWidth < size && number > 1000) {
    number = parseInt(number / 1000, 10);
  }
  return number;
}

export default ({ append = false, prepend = false, number = 0, label = "", width = false }) => {
  let interval = useRef(), ref = React.createRef();

  const [count, setCount] = useState(() => 0)
  const [, onResize] = useState(() => 0)

  const [increment] = useState(() => {
    let defaultIncrement = Math.ceil(number / (25));
    return defaultIncrement ? defaultIncrement : 1;
  })

  let formattedCount = count;
  try {
    formattedCount = formatNumberByViewportSize(count);
  } catch (e) {
    //console.log(e);
  }

  useEffect(() => {
    function tick() {
      if (count >= number) {
        clearInterval(interval.current)
        return
      }
      if (count + increment >= number) {
        setCount(number)
        clearInterval(interval.current)
        return
      }
      setCount(count + increment)
    }
    const observer = new IntersectionObserver(([entry], io) => {
      if (entry.isIntersecting) {
        let $target = entry.target;
        interval.current = setInterval(tick, 40);
        io.unobserve($target);
      }
    });

    let $target = ReactDOM.findDOMNode(ref.current);
    if ($target) {
      observer.observe($target);
    }

    window.addEventListener('resize', onResize);

    return () => {
      observer.disconnect();
      clearInterval(interval.current);
      window.removeEventListener('resize', onResize);
    }
  }, [ref, count, number, increment]);

  if (formattedCount < count) {
    append = `k ${append ? append : ''}`;
  }
  let style = {};
  if (width) {
    style.width = `${width}px`;
  }
  return (
    <div className="countable" ref={ref}>
      <Wrapper block="countable" style={style}>
        {prepend && (<span className="countable__prepend">{prepend}</span>)}
        <span className="countable__number">{formattedCount.toLocaleString()}</span>
        {append && (<span className="countable__append">{append}</span>)}
      </Wrapper>
      <span className="countable__label">{label}</span>
    </div>
  )
}