import React, { useContext } from "react"
import Logo from "./Elements/Logo"
import MenuList from "./Elements/MenuList"
import Wrapper from "./Elements/Wrapper"
import GdprBadge from "./Elements/GdprBadge"
import { OptionsContext } from "../templates/Page"
import './Footer.scss';

export default ({menu}) => {
  let {logo, copyright, social, socialTitle=""} = useContext(OptionsContext);
  return (
    <footer className="page__footer footer">
      <Wrapper>
        <Wrapper block="footer" modifier="logo">
          <Logo modifier="footer" svg={logo} />
          <p dangerouslySetInnerHTML={{__html: copyright}} />
        </Wrapper>
        <nav id="footer-menu" className="page__menu menu menu--footer">
          <MenuList modifier="root" items={menu} />
        </nav>
        <Wrapper block="footer" modifier="social">
          <MenuList modifier="social" items={[{label: socialTitle, path: '#', menu: social}]} />
          <GdprBadge/>
        </Wrapper>
      </Wrapper>
    </footer>
  )
}