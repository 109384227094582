import React from "react"
import Block from "../Elements/Block"
import Wrapper from "../Elements/Wrapper"
import Headline from "../Elements/Headline"
import Copy from "../Elements/Copy"
import TickList from "../Elements/TickList"
import { LinkButton } from "../Elements/Button"
import "./Product.scss"
import Scroll from "../Elements/Scroll";

export default ({id, data: {headline, copy, features, primary, secondary = false, scroll}}) => (
  <Block id={id} block="product">
    <Wrapper>
      <Headline modifier="headline">{headline}</Headline>
      <Copy copy={copy} />
      <TickList items={features} />
      <Wrapper block="product" modifier="buttons">
        <LinkButton modifier="primary" link={primary} />
        {secondary && (<LinkButton modifier="secondary" link={secondary} />)}
      </Wrapper>
        <Scroll enabled={scroll} />

    </Wrapper>
  </Block>
)