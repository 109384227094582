import React, { useContext } from "react"
import Helmet from "react-helmet"
import { LanguageContext, OptionsContext } from "../../templates/Page"
import favicon from '../../images/favicon.png'

function SEO({ meta }) {

  let { currentLang: lang } = useContext(LanguageContext);
  let option = useContext(OptionsContext);
  const metaDescription = meta.description || option.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={meta.title}
      titleTemplate={`%s | ${option.title}`}
      link={[
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: meta.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: option.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        meta.keywords.length > 0
          ? {
            name: `keywords`,
            content: meta.keywords.join(`, `),
          }
          : []
      )}
    />
  )
}

export default SEO;
