import React from "react"
import Svg from "./Svg";
import "./Scroll.scss"

export default ({enabled}) => {
    if (enabled === '0') {
        return null
    }

    return (
        <div className="cta cta--scroll" onClick={() => document.scrollingElement.scrollBy(0, document.scrollingElement.clientHeight * 0.7)}>
              <span className="cta__icon">
                  <Svg id="arrow"/>
              </span>
        </div>
    )


}